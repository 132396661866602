import PropTypes from 'prop-types'

import { Toaster } from 'react-hot-toast'

const Toast = ({ variant = 'bottom-center', duration = 3000 }) => (
  <Toaster
    position={variant}
    toastOptions={{
      className: 'bg-transparent shadow-none rounded-md min-w-96',
      style: { margin: 0, padding: '0.5rem' },
      success: {
        className: 'min-w-64 bg-success-light text-success-dark',
        duration,
        // theme: {
        //   primary: colors.success.light,
        //   secondary: colors.success.dark,
        // },
      },
      error: {
        duration,
        className: 'min-w-64 bg-error-light text-error-dark',
        // theme: {
        //   primary: colors.error.main,
        //   secondary: colors.common.white,
        // },
      },
    }}
  />
)

Toast.displayName = 'Toast'

Toast.propTypes = {
  variant: PropTypes.oneOf([
    'top-center',
    'top-left',
    'top-right',
    'bottom-center',
    'bottom-left',
    'bottom-right',
  ]),
  duration: PropTypes.number,
}

export default Toast
